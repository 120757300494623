/**
 * Creates a debounced function that delays invoking the provided function
 * until after the specified delay in milliseconds has passed since the last time 
 * the debounced function was invoked.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} delay - The number of milliseconds to delay the function call.
 * @returns {Function} - Returns the debounced function.
 */
function debounce(func, delay) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

window.debounce = debounce